body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* dashboard styling */

#dashboard-container {
  overflow-x: auto;
}

/* dashboard - machines */
#mach-table > thead > tr > th {
  font-weight: bolder;
}

/* dashboard - org test summary */

#ots-table > thead > tr > th {
  font-weight: bolder;
}

#ots-table > tbody > tr > td > p {
  margin: 0;
  text-align: left;
  padding-left: 10px;
}

.long-label {
  font-size: 10px;
}

/* Consumer Modal Create */

.consumerModalCreateContent > div {
  display: block;
}